import React, {Suspense} from 'react';
import {Container, Row, Col, Nav} from 'react-bootstrap';
import {  BsFillCaretRightFill } from "react-icons/bs";

//Loading
import LoadingSvg from '../img/loading.svg';

//Page
import Kontakt from './kontakt';

//Component
const  Banner = React.lazy (() =>import('../components/contentComponents/bannerComponent'));
const  Carousel = React.lazy (() =>import('../components/contentComponents/carouselComponent'));

function home () {

    var icon = <BsFillCaretRightFill/>;
    var loading =   <img alt="RW-Loading" src={LoadingSvg} className="align-self-center mr-3 shadow-sm" height={100} width={100} /> 
    var styles={height:'360px'};

    return(
        <div>
        <Suspense fallback={<div className="text-center banner">{loading}</div>}>
            <Banner/>
        </Suspense>

        <Container>
          
        <Row>
            <Col><p>Wir sind Ihr Ansprechpartner für:</p></Col>
        </Row>
        <Row>
            <Col>

        <Nav className="flex-column">
        <Nav.Link href="/projekte/pv">{icon} PV Anlagen</Nav.Link>
        <Nav.Link href="/projekte/elektro">{icon} Elektroinstallationen</Nav.Link>
        <Nav.Link href="/projekte/elektro"> {icon} Planung von Elektroanlagen</Nav.Link>
        <Nav.Link href="/projekte/edv">{icon} EDV & Netzwerktechnik</Nav.Link>
        </Nav>

            </Col>
        </Row>
        <br/>
        <Row>
            <Col>
           Bei Fragen rund um die Elektrotechnik, ob im Gewerbeobjekt oder privat bei Ihnen zu Hause, sind Sie bei uns genau richtig.
            </Col>
        </Row>
        <Row>
            <Col> <span className="font-weight-bold"> Wir beraten Sie gerne!</span>
            </Col>
        </Row>
        <br/>
        <Row>

        <Col md={{ span: 10, offset: 1 }}>
            <Suspense fallback={<div className="text-center" style={styles}>{loading}</div>}>
            <Carousel/> 
            </Suspense>
        </Col>
           
        </Row>
        <Row>
        <Col><Kontakt/></Col>
        </Row>
        </Container>
        </div>
    );
}

export default home;