import React from 'react';
import {Image,Card} from 'react-bootstrap';

function projekte(props) {
    
    const handleClick = (value,id) =>{
        props.onClick(value,id);
    }
    return(
    <Card>
    <Image rounded className='img-fluid' src={props.src} onClick={() => handleClick(true,props.id)}/>
    </Card>
    );
}

export default projekte;




