import React from 'react';
import {Navbar,Nav,NavDropdown} from 'react-bootstrap';
import {FiPhoneCall} from 'react-icons/fi';

import logo from '../../img/animation/Frame 6.svg';


function headerContainer () {
    return(
        <header>
        <Navbar className="shadow" bg="light" expand="lg" fixed="top">
            <Navbar.Brand href="/home">
            <img
        alt="RW-Logo"
        src={logo}
        className="align-self-center mr-3 shadow"
        height={32}
        width={32}
      /> 
                RW-Elektrotechnik
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                <Nav.Item>
                <Nav.Link href="/kontakt" >Kontakt</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <NavDropdown title="Projekte" id="basic-nav-dropdown" href="/projekte">
                <NavDropdown.Item href="/projekte/pv">Pv-Anlagen</NavDropdown.Item>
                <NavDropdown.Item href="/projekte/elektro">Elektroinstallationen</NavDropdown.Item>
                <NavDropdown.Item href="/projekte/edv">EDV & Netzwerktechnik</NavDropdown.Item>
                </NavDropdown>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link href="/impressum">Impressum</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link href="/datenschutz">Datenschutz</Nav.Link>
                </Nav.Item>
                </Nav>
                <NavDropdown.Divider />
                <Nav>
                <a href="tel:05233951538"><FiPhoneCall/> 05233 - 95 15 38</a>
                </Nav>
             </Navbar.Collapse>
        </Navbar>
        </header>
    );
}

export default headerContainer;
