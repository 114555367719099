import React from 'react';
import {Container, Row, Col, Jumbotron} from 'react-bootstrap';
import {FiPhoneCall} from 'react-icons/fi';
import {FiMail} from 'react-icons/fi';




function impressum() {
    return(
        <Jumbotron>
      <Container>
          <Row>
              <Col><h2>Impressum</h2></Col>
          </Row>
          <Row>
              <Col><p>Verantwortlich für den Inhalt dieser Webpräsenz ist:</p></Col>
          </Row>
          <Row>
              <Col>
              <span>RW-Elektrotechnik GmbH & Co. KG</span>
             <br></br>
             <br></br>
              <span>Im Grünen Winkel 5</span>
              <br></br>
              <span>32805 Horn Bad Meinberg</span>
              <br></br>
              <span>Deutschland</span>
              <br></br>
              <br></br>
              </Col>
          </Row>
          <Row>
              <Col>
                <a href="tel:05233951538"><FiPhoneCall/> 05233 - 95 15 38</a>
                <br></br>
                <a href="mailto:rolfwinand58@gmail.com"><FiMail/> rolfwinand58@gmail.com</a>
                <br></br>
                <br></br>
              </Col>
          </Row>
         
          <Row>
              <Col>
              <span>Geschäftsführer:</span>
              <br></br>
             <p>Rolf Winand</p> 
              </Col>
          </Row>

          <Row>
              <Col>
              <p>USt.-Id.-Nr: (DE 278 314 382)</p>
              </Col>
          </Row>
          <Row>
              <Col>
              <span>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</span>
              </Col>
          </Row>


      </Container>

      </Jumbotron>
    );
}

export default impressum;
