import React from 'react';
import {Card} from 'react-bootstrap';



function projekte(props) {
    return(

    <Card>
        <Card.Img variant="top" src={props.src}/>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
        </Card.Body>
      </Card>

    );
}

export default projekte;




