import React from 'react';

import {Navbar,Nav} from 'react-bootstrap';

function footerContainer () {
    
  
  return(
      
      <footer  className="footer mt-auto" >
        <Navbar className="footerColor justify-content-center" variant="dark">
          <Nav>
            <Nav.Item>
              <Nav.Link  href="/kontakt">Kontakt</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link  href="/impressum">Impressum</Nav.Link>
            </Nav.Item> 
            <Nav.Item> 
              <Nav.Link href="/datenschutz">Datenschutz</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </footer> 

    );
}

export default footerContainer;