import React from 'react';
import {Jumbotron,Alert} from 'react-bootstrap';


function nomatch () {
    return(
<Jumbotron>
 <Alert  variant={'danger'}>
    Error 404:
    <p>Page not Found</p>
    </Alert>

</Jumbotron>
    );
}

export default nomatch;
