import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


class lightboxComponent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      photoIndex:this.props.photoIndex,
    };

  }

  handleClose = (isClose) => {
 this.props.onClose(isClose);
  }


 
  render() {
    const { photoIndex } = this.state;
    const { imgAr } = this.props;
    
    return (
    
          <Lightbox
            mainSrc={imgAr[photoIndex]}
            nextSrc={imgAr[(photoIndex + 1) % imgAr.length]}
            prevSrc={imgAr[(photoIndex + imgAr.length - 1) % imgAr.length]}
            onCloseRequest={() => this.handleClose(false)}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + imgAr.length - 1) % imgAr.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % imgAr.length,
              })
            }
          />
        )}
  }


export default lightboxComponent;